import React from "react"
import { navigate } from "gatsby"

import Layout from "../../layouts"

import { getCoinItemList } from "../../api/item"
import { getUserSelf } from "../../api/user"
import "./sell-coin.css"
import MembershipSidebar from "../../components/MembershipSidebar"
import { MEMBERSHIP_SIDEBAR } from "../../utils/constant"

class SellCoin extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      coinItemList: [],
      visible: false,
      coinAmount: 0,
    }
  }

  componentDidMount() {
    this.initCoinItem()
    this.initCoin()
  }

  initCoinItem = async () => {
    const res = await getCoinItemList()
    if (res.data.success) {
      this.setState({ coinItemList: res.data.data })
    }
  }

  initCoin = async () => {
    const res = await getUserSelf()
    if (res && res.data.success) {
      const { coinAmount } = res.data.data
      this.setState({ coinAmount })
    }
  }

  render() {
    const { coinItemList } = this.state
    return (
      <Layout isSpacerDisplay={true} title="墨点充值">
        <div className="mdnice-membership-container">
          <div className="mdnice-membership-table-container">
            <MembershipSidebar sidebarType={MEMBERSHIP_SIDEBAR.SELL_COIN} />

            <div className="mdnice-membership-content">
              <a target="_blank" href="https://www.mdnice.com/writing/b41bee9f449d4c24ac393d45698e68d3">
                什么是墨点？
              </a>
              <p style={{ marginTop: "20px", color: "rgb(48,132,214)" }}>
                墨点余额：{this.state.coinAmount}
              </p>
              <div className="mdnice-membership-item-list">
                {coinItemList.map(item => (
                  <div
                    className="mdnice-sell-active-code-coloum"
                    key={item.name}
                    onClick={() => {
                      navigate(`/items?id=${item.itemId}&type=coin`)
                    }}
                  >
                    <div className="mdnice-sell-active-code-row">
                      <p className="mdnice-sell-active-code-name">
                        {item.name}
                      </p>
                      <p className="mdnice-sell-active-code-price">
                        <span>¥</span>
                        {item.price / 100}
                      </p>
                      <p className="mdnice-sell-active-code-device ">
                        {item.coinNum} 滴
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default SellCoin
